.search-results {
  padding: 3rem 0;
  display: flex;
  flex-direction:column;
  @media only screen and (max-width: $bp-medium){
    display: contents;
    padding: auto;
  }
}

.results {
  list-style: none;
  margin-bottom: 2rem;
}

.pagination {
  margin-top: auto;
  padding: 0 3.5rem;

  &::after {
    content: '';
    display: table;
    clear: both;
  }
  &__num {
    text-align: center;
  }

  &__btn {
    &--prev {
      float: left;
    }
    &--next {
      float: right;
    }
    &--sort {
      margin: 0 auto;
    }
  }
}

.copyright {
  color: $color-grey-dark-2;
  font-size: 1.2rem;
  padding: 0 3.5rem;
  margin-top: 4rem;

  .twitter-link:link,
  .twitter-link:visited {
    color: $color-grey-dark-2;
  }
  @media only screen and (max-width: $bp-medium){
    opacity: 0;
  }
}
