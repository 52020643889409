.add-ing-window-2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100rem;
    background-color: white;
    border-radius: 9px;
  
    padding: 5rem 6rem;
    box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.25);
    z-index: 1000;
    transition: all 0.5s;
    max-height: 100%;
    overflow-y: auto;  
    .btn--close-modal-2 {
      font-family: inherit;
      color: inherit;
      position: absolute;
      top: 0.5rem;
      right: 1.6rem;
      font-size: 3.5rem;
      cursor: pointer;
      border: none;
      background: none;
    }
    
    @media only screen and (max-width: $bp-medium){
      max-width: 100%;
      overflow-x: auto;

    }
  }  
  .overlay-2 {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(4px);
    z-index: 100;
    transition: all 0.5s;
  }